import React, { useEffect } from 'react';

const CookieConsentScript = () => {
  useEffect(() => {
    // Cria um elemento de script
    const script = document.createElement('script');
    script.src = "//cdn.cookie-script.com/s/cea5a2c3e52358d29c6d5c6524f05d72.js";
    script.type = "text/javascript";
    script.charset = "UTF-8";
    // Adiciona o script no head do documento
    document.head.appendChild(script);

    // Limpeza do efeito para remover o script se o componente for desmontado
    return () => {
      document.head.removeChild(script);
    };
  }, []); // O array vazio garante que o efeito só roda uma vez

  return null; // Este componente não precisa renderizar nada
};

export default CookieConsentScript;