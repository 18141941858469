import React, { useState } from 'react';
import '../index.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextInput from '../components/TextInput';
import axios from "axios";
import $ from "jquery";
import ReactGA from "react-ga";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
			nameValue: '',
			emailValue: '',
      passwordValue: '',
      password2Value: '',
      fileValue: '',
      termsValue: false,
      nameError: 0,
      emailError: 0,
      passwordError: 0,
      password2Error: 0,
      fileError: 0,
      termsError: 0,
      formSuccess: false,
		}
  }
  componentDidMount(){
    ReactGA.pageview(window.location.pathname);
  }

  handleNameChange = (e) => {
    this.setState({ nameValue: e.target.value });
  }
  handleEmailChange = (e) => {
    this.setState({ emailValue: e.target.value });
  }
  handlePasswordChange = (e) => {
    this.setState({ passwordValue: e.target.value });
  }
  handlePassword2Change = (e) => {
    this.setState({ password2Value: e.target.value });
  }
  handleFileChange = (e) => {
    $('#selected_filename').text($('#fileinput')[0].files[0].name);
    this.setState({ fileValue: e.target.files[0] });
  }
  handleTermsChange = (e) => {
    this.setState({ termsValue: !this.state.termsValue });
  }
  
  registerPro = () => {
    if(this.state.nameValue != '' && this.state.emailValue != '' && this.state.passwordValue != '' && this.state.password2Value != '' && this.state.fileValue != ''){
      this.setState({ nameError: 0 });
      this.setState({ emailError: 0 });
      this.setState({ passwordError: 0 });
      this.setState({ password2Error: 0 });
      this.setState({ fileError: 0 });
      this.setState({ termsError: 0 });
      if(this.state.passwordValue != this.state.password2Value){
        this.setState({ password2Error: 2 });
      }
      else if(!this.state.termsValue){
        this.setState({ termsError: 1 });
      }
      else{
        const registerData = new FormData() ;
        registerData.append('name', this.state.nameValue);
        registerData.append('email', this.state.emailValue);
        registerData.append('password', this.state.passwordValue);
        registerData.append('file', this.state.fileValue);
        axios.post(global.config.apiUrl+"registerPro", registerData)
        .then(res => {
          this.setState({ formSuccess: true });
        })
      }
    }
    else{
      this.setState({ nameError: 0 });
      this.setState({ emailError: 0 });
      this.setState({ passwordError: 0 });
      this.setState({ password2Error: 0 });
      this.setState({ termsError: 0 });
      this.setState({ fileError: 0 });
      if(this.state.nameValue == ''){
        this.setState({ nameError: 1 });
      }
      if(this.state.emailValue == ''){
        this.setState({ emailError: 1 });
      }
      if(this.state.passwordValue == ''){
        this.setState({ passwordError: 1 });
      }
      if(this.state.password2Value == ''){
        this.setState({ password2Error: 1 });
      }
      if(this.state.fileValue == ''){
        this.setState({ fileError: 1 });
      }
    }
  }

  render() {
    return (
      <div className="home-container">
        <div className="home-banner">
          <Container>
            <Row>
              <Col md={6} className={this.state.formSuccess ? 'hide-form' : null}>
                <p className='question-text'>
                Entre em contacto com um dos colaboradores RayBlood, caso tenha alguma dúvida ou questão relativa à Doença do Enxerto Contra o Hospedeiro Associada à Transfusão. O colaborador responderá com a maior brevidade possível. Obrigado.
                </p>
              </Col>
              <Col md={6} className={this.state.formSuccess ? 'hide-form' : null}>
                <div className="question-form-container">
                  <form id="question-form">
                    <div className="input-container">
                      <input type="text" value={this.state.nameValue} name="name" onChange={this.handleNameChange} />
                      <label className={this.state.nameValue!='' && 'filled'} htmlFor="name">
                        Nome
                      </label>
                      {this.state.nameError == 1 &&
                        <p className="error-message">*Campo Obrigatório</p>
                      }
                    </div>
                    <div className="input-container">
                      <input type="text" value={this.state.emailValue} name="email" onChange={this.handleEmailChange} />
                      <label className={this.state.emailValue!='' && 'filled'} htmlFor="email">
                        E-mail
                      </label>
                      {this.state.emailError == 1 &&
                        <p className="error-message">*Campo Obrigatório</p>
                      }
                    </div>
                    <div className="input-container">
                      <input type="password" value={this.state.passwordValue} name="password" onChange={this.handlePasswordChange} />
                      <label className={this.state.passwordValue!='' && 'filled'} htmlFor="password">
                        Password
                      </label>
                      {this.state.passwordError == 1 &&
                        <p className="error-message">*Campo Obrigatório</p>
                      }
                    </div>
                    <div className="input-container">
                      <input type="password" value={this.state.password2Value} name="password2" onChange={this.handlePassword2Change} />
                      <label className={this.state.password2Value!='' && 'filled'} htmlFor="password2">
                        Confirmar Password
                      </label>
                      {this.state.password2Error == 1 &&
                        <p className="error-message">*Campo Obrigatório</p>
                      }
                      {this.state.password2Error == 2 &&
                        <p className="error-message">Passwords não coincidem</p>
                      }
                    </div>
                    <div className="input-container">
                      <input type="file" name="file" id="fileinput" style={{display: "none"}} onChange={this.handleFileChange} />
                      <div id="falseinput-container">
                        <button id="falseinput" type="button" onClick={() => $("#fileinput").trigger("click")}>Escolher ficheiro</button>
                        <span id="selected_filename">Nenhum ficheiro selecionado</span>
                      </div>
                      <label className='filled' htmlFor="file">
                        Faça upload do seu cartão da Ordem dos Médicos
                      </label>
                      {this.state.fileError == 1 &&
                        <p className="error-message">*Campo Obrigatório</p>
                      }
                    </div>
                    <div className="input-checkbox-container">
                      <label className="fakecheckbox">
                          <input type="checkbox" className="item-default-input" id="selectAllMarkets" onChange={this.handleTermsChange} name="checkbox" />
                          Aceito os<a href="/termos-e-condicoes-medico" target='_blank'>termos e condições</a>
                      </label>
                      {this.state.termsError == 1 &&
                        <p className="error-message error-message-terms">Os termos e condições não foram aceites ainda</p>
                      }
                    </div>
                    <button type="button" onClick={this.registerPro} className="btn-secondary">Registar</button>
                  </form>
                </div>
              </Col>
              <Col md={12} className={this.state.formSuccess ? 'form-success' : 'hide-form'}>
                <div>
                  <p className='success-text'>
                    Profissional registado com sucesso. Após verificarmos os seus dados, procederemos à confirmação para poder começar a utilizar a plataforma Rayblood. Obrigado.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Register;
