import React from 'react';
import '../index.css';
import '../config.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {Helmet} from "react-helmet";
import DOMPurify from 'dompurify';
import axios from "axios";
import $ from "jquery";
import phoneIcon from '../images/phone-icon.svg';
import androidIconBlack from '../images/android-icon-black.svg';
import androidIconWhite from '../images/android-icon-white.svg';
import appleIconBlack from '../images/apple-icon-black.svg';
import appleIconWhite from '../images/apple-icon-white.svg';
import shareIcon from '../images/share-icon.svg';
import addIcon from '../images/add-icon.svg';
import tickIcon from '../images/tick-icon.svg';
import appleStep3 from '../images/apple-step-3.png';
import androidStep1 from '../images/android-step-1.png';
import androidStep2 from '../images/android-step-2.png';
import androidStep3 from '../images/android-step-3.png';
import ReactGA from "react-ga";
import {
	Link
  } from "react-router-dom";



class WebApp extends React.Component {
  constructor(props){
    super(props);
		this.state = {
			pageTitle: '',
			pageText: '',
      pageTitleSEO: '',
      pageDescSEO: '',
      OSSelector: 1,
		}
	}

  componentDidMount(){
    ReactGA.pageview(window.location.pathname);
  }
  scrollEvent = (e) => {
    e.preventDefault();
    document.getElementById("webapp-page").scrollIntoView({
      behavior: 'smooth'
    });
  }
  render(){
    return (
      <div className="home-container">
        <Helmet>
          <title>{this.state.pageTitleSEO}</title>
          <meta name="description" content={this.state.pageDescSEO}/>
        </Helmet>
        <div className="home-banner">
          <Container>
            <Row>
              <Col md={7}>
                <div>
                  <h1>
                    Siga estas simples instruções para instalar a web app no seu telemóvel, de modo a ter acesso fácil ao seu cartão Rayblood.
                  </h1>
                </div>
              </Col>
              <Col md={5}>
                <div className="phone-img-container">
                  <img src={phoneIcon} className="phone-img" />
                  <div className="phone-img-circle"></div>
                </div>
              </Col>
            </Row>
          </Container>
          <button onClick={this.scrollEvent} className="btn-scroll">
            Scroll <div></div>
          </button>
        </div>
        <div id="webapp-page">
          <div className="OS-selector">
            <Button className={"android-selector " + (this.state.OSSelector == 1 ? "active " : "")} onClick={() => this.setState({ OSSelector: 1 })}>
              <img src={(this.state.OSSelector == 1 ? androidIconWhite : androidIconBlack)}></img> Android
            </Button>
            <Button className={"apple-selector " + (this.state.OSSelector == 2 ? "active " : "")} onClick={() => this.setState({ OSSelector: 2 })}>
              <img src={(this.state.OSSelector == 2 ? appleIconWhite : appleIconBlack)}></img> Apple
            </Button>
          </div>
          {this.state.OSSelector == 1 &&
            <div className="android-steps os-steps">
              <Container>
                <p className="os-steps-title">Para instalar a web app ou o seu cartão digital Rayblood<br/>no seu telemóvel, siga os seguintes passos:</p>
                <Row>
                  <Col className="step-col">
                    <img src={androidStep1} className="circle-img"></img>
                    <h3>Passo 1</h3>
                    <p>Carregue no botão<br/>menu com 3 pontos<br/>do seu browser</p>
                  </Col>
                  <Col className="step-col">
                    <img src={androidStep2} className="circle-img"></img>
                    <h3>Passo 2</h3>
                    <p>Selecionar opção<br/>Adicionar ao ecrã<br/>principal</p>
                  </Col>
                  <Col className="step-col">
                    <img src={androidStep3} className="circle-img"></img>
                    <h3>Passo 3</h3>
                    <p>Defina o nome<br/>que pretende e clique<br/>em adicionar</p>
                  </Col>
                  <Col className="step-col">
                    <div className="icon-circle">
                      <img src={tickIcon} className="icon-circle-img"></img>
                    </div>
                    <h3>Passo 4</h3>
                    <p>Feito!<br/>Encontre a sua web<br/>app no seu telemóvel</p>
                  </Col>
                </Row>
              </Container>
            </div>
          }
          {this.state.OSSelector == 2 &&
            <div className="apple-steps os-steps">
              <Container>
                <p className="os-steps-title">Para instalar a web app ou o seu cartão digital Rayblood<br/>no seu telemóvel, siga os seguintes passos:</p>
                <Row>
                  <Col className="step-col">
                    <div className="icon-circle">
                      <img src={shareIcon} className="icon-circle-img"></img>
                    </div>
                    <h3>Passo 1</h3>
                    <p>Carregue no botão<br/>Partilhar</p>
                  </Col>
                  <Col className="step-col">
                    <div className="icon-circle">
                      <img src={addIcon} className="icon-circle-img"></img>
                    </div>
                    <h3>Passo 2</h3>
                    <p>Carregue no botão<br/>Adicionar ao ecrã principal</p>
                  </Col>
                  <Col className="step-col">
                    <img src={appleStep3} className="circle-img"></img>
                    <h3>Passo 3</h3>
                    <p>Defina o nome<br/>que pretende e clique<br/>em adicionar</p>
                  </Col>
                  <Col className="step-col">
                    <div className="icon-circle">
                      <img src={tickIcon} className="icon-circle-img"></img>
                    </div>
                    <h3>Passo 4</h3>
                    <p>Feito! Encontre a sua web<br/>app no seu telemóvel</p>
                  </Col>
                </Row>
              </Container>
            </div>
          }
          <div style={{textAlign: "center", marginTop: 40}}>
            <Link to="/cartao-irradiacao">
              <Button variant="primary">
                Consultar cartão Individual de irradiação
              </Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default WebApp;
